<template>
  <v-card class="mb-6">
    <v-card-title class="d-flex justify-content-between">
      <span> Tipos de vales </span>
      <v-spacer />

      <v-btn color="info" outlined @click="openModal('create')">
        <v-icon size="30" class="me-2">
          {{ icons.mdiPlaylistPlus }}
        </v-icon>
        Novo
      </v-btn>
    </v-card-title>

    <v-data-table :headers="headers" :items="itemsTable" :loading="isLoading" loading-text="Carregando dados...">
      <template v-slot:no-data>
        <span>Sem informações para exibir</span>
      </template>

      <template v-slot:item.event.name="{ item }">
        {{ item.event.code ? item.event.code : '' }} - {{ item.event.name ? item.event.name : '' }}
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ item.created_at ? dateFormat(item.created_at) : '' }}
      </template>

      <template v-slot:item.edit="{ item }">
        <v-icon medium class="me-2" color="info" @click="openModal('details', item)">
          {{ icons.mdiPencil }}
        </v-icon>
      </template>

      <template v-slot:item.delete="{ item }">
        <v-icon medium class="me-2" color="error" @click="deleteRegister(endpointDelete, item.id)">
          {{ icons.mdiDelete }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="showModal" width="500px">
      <Modal :event-list="eventList" @updatedTable="updatedTable()" @close="showModal = false"></Modal>
    </v-dialog>

    <v-dialog v-model="showDetails" width="500px">
      <Details
        :key="dataDetails.id"
        :data="dataDetails"
        :event-list="eventList"
        @updatedTable="updatedTable()"
        @close="showDetails = false"
      ></Details>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'
import Details from './Details.vue'
import Modal from './Modal.vue'

export default {
  components: {
    Modal,
    Details,
  },

  mixins: [formatters, messages],

  data() {
    return {
      showModal: false,
      showDetails: false,

      dataDetails: {},
      eventList: [],

      endpointDelete: 'api/v1/integration/type_voucher/destroy',

      headers: [
        {
          text: 'NOME',
          value: 'description',
          sortable: false,
          align: '',
        },
        {
          text: 'EVENTO',
          value: 'event.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA DA CRIAÇÃO',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'EDITAR',
          value: 'edit',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DELETAR',
          value: 'delete',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      isLoading: false,

      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
      },
    }
  },

  created() {
    this.getItemsTable()
    this.getEventList()
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns
        .get('api/v1/integration/type_voucher/index')
        .then(response => {
          this.itemsTable = response.data.data
        })
        .catch(error => {
          this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: error })
        })
        .finally(() => (this.isLoading = false))
    },

    async getEventList() {
      await axiosIns.get('api/v1/integration/event/index').then(response => {
        response.data.data.map(({ id, name, code }) => {
          this.eventList.push({
            id,
            name,
            code,
            description: `${code} - ${name}`,
          })
        })
      })
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal(type, data = {}) {
      const modal = {
        create: () => (this.showModal = true),
        details: () => (this.showDetails = true),
      }

      if (modal.hasOwnProperty(type)) {
        modal[type]()
        this.dataDetails = data
      }
    },
  },
}
</script>
