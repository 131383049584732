<template>
  <v-card>
    <v-card-title class="justify-center mb-2"> Cadastrar </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-text-field v-model="name" label="Nome" outlined dense />

        <v-autocomplete
          v-model="eventIdSelected"
          :items="eventList"
          item-text="description"
          item-value="id"
          label="Evento"
          clearable
          outlined
          dense
        />
      </v-form>

      <div class="d-flex justify-end mt-2">
        <v-btn color="error" @click="closeModal()"> Fechar </v-btn>

        <v-btn color="info" class="ml-5" @click="sendModalData()">
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate />
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  props: {
    eventList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      name: '',
      eventIdSelected: '',

      isLoading: false,
    }
  },

  methods: {
    async sendModalData() {
      this.isLoading = true

      const body = {
        description: this.name.toUpperCase(),
        events_id: this.eventIdSelected,
      }

      await axiosIns
        .post('api/v1/integration/type_voucher/store', body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.resetForm()
          this.closeModal()
        })
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()
    },
  },
}
</script>
