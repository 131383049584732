<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      <span>Editar dados</span>
      <v-spacer />

      <v-tooltip left color="warning">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="expand = !expand">
            <v-icon color="warning" v-bind="attrs" v-on="on">
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
        </template>

        <span>Editar</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="computedDescription"
        label="Nome do motivo"
        :append-icon="icons.mdiPencil"
        outlined
        dense
      />

      <v-text-field v-model="formatEventDescription" label="Nome do motivo" readonly outlined dense />

      <v-divider class="mb-8" />

      <v-expand-transition>
        <v-card v-show="expand">
          <v-autocomplete
            v-model="eventIdSelected"
            :items="eventList"
            item-text="description"
            item-value="id"
            label="Alterar Evento"
            clearable
            outlined
            dense
          />
        </v-card>
      </v-expand-transition>

      <div class="d-flex justify-end mt-2">
        <v-btn color="error" @click="closeModal()"> Cancelar </v-btn>

        <v-btn color="info" class="ml-5" @click="sendModalData()">
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate />
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPencil } from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: [Array, Object],
      required: true,
    },

    eventList: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {
      name: '',
      eventIdSelected: '',

      expand: false,
      isLoading: false,

      icons: {
        mdiPencil,
      },
    }
  },

  computed: {
    formatEventDescription() {
      return `${this.data?.event?.code} - ${this.data?.event?.name}` ?? ''
    },

    computedDescription: {
      get() {
        return this.data?.description ?? ''
      },

      set(description) {
        this.data.description = description
      },
    },
  },

  methods: {
    async sendModalData() {
      this.isLoading = true

      const body = {
        description: this.data.description,
        events_id: !this.eventIdSelected ? this.data.event.id : this.eventIdSelected,
      }

      await axiosIns
        .put(`api/v1/integration/type_voucher/update/${this.data.id}`, body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.expand = false
          this.isLoading = false
          this.closeModal()
        })
    },
  },
}
</script>
